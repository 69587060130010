<template>
	<section class="section">
		<div class="container">
			<center class="welcome">
				<h1>Welcome</h1>
				<p>Smartkast is an event agency that specializes in digital voting and market promotion programs for
					brands that target the entertainment industry with exceptional interest in youth events.</p>
				<input class="search-box" type="search" v-model="keyword" placeholder="Search by event title">
			</center>
			<Placeholder v-if="isLoading" />
			<div class="row listings-grid" v-else-if="filteredEvents.length > 0">
				<div class="col-sm-4 col-md-3" v-for="(event, i) in filteredEvents" :key="i">
					<div class="listings-grid__item">
						<div class="listings-grid__main">
							<VLazyImage :src="event.image" :src-placeholder="placeholder" />
							<div class="listings-grid__price">{{ event.type }}</div>
						</div>
						<div class="listings-grid__body">
							<h5>{{ event.title }}</h5>
							<small>{{ event.event_date }}</small>
						</div>
						<div class="listings-button">
							<button class="btn btn-success btn-block" :disabled="event.is_past" @click="view(event)">
								{{ event.button_text }}
							</button>
						</div>
					</div>
				</div>
			</div>
			<NotFound v-else />
		</div>
	</section>
</template>

<script>
import dayjs from 'dayjs';
import { getFirestore, collection, query, orderBy, where, onSnapshot } from 'firebase/firestore';
// limit,
const db = getFirestore();
export default {
	data() {
		return {
			events: [],
			isLoading: false,
			per_page: 100,
			last_visible: null,
			placeholder: '/img/placeholder.jpg',
			keyword: '',
		}
	},
	created() {
		this.getEvents()
	},
	methods: {
		getEvents() {
			this.isLoading = true;
			const ref = collection(db, 'events');
			// const l = limit(this.per_page);
			const q = query(ref, where("go_live", "==", true), orderBy('date', 'desc'));
			onSnapshot(q, snapshot => {
				this.events = snapshot.docs.map(doc => {
					const data = doc.data();
					data.id = doc.id;
					data.event_date = dayjs(data.date.toDate()).format('MMMM DD, YYYY');
					const today = dayjs().unix();
					const expiry = dayjs(data.date.toDate()).unix();
					const expired = today > expiry ? true : false;
					data.is_past = expired;
					if (expired) {
						data.button_text = 'EXPIRED';
					} else {
						data.button_text = 'SELECT EVENT';
					}
					return data;
				});
				this.last_visible = snapshot.docs[snapshot.docs.length - 1];
				this.isLoading = false;
			})
		},
		view(item) {
			const { id, type } = item;
			switch (type) {
				case 'CONTEST':
					this.$router.push(`/events/c/${id}`).catch(() => { });
					break;
				case 'FUNDRAISER':
					this.$router.push(`/events/f/${id}`).catch(() => { });
					break;
				case 'REGISTRATION':
					this.$router.push(`/events/r/${id}`).catch(() => { });
					break;
				default:
					break;
			}
			// if (type == 'CONTEST') {
			// } else {
			// 	this.$router.push(`/events/f/${id}`).catch(() => { });
			// }
		},
	},
	computed: {
		filteredEvents() {
			if (this.keyword == '') {
				return this.events;
			}
			return this.events.filter(event => {
				return event.title.toLowerCase().includes(this.keyword.toLowerCase());
			});
		}
	}
};
</script>
